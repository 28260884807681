import "./src/assets/sass/main.scss"

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (window.MemberStack !== undefined) {
    window.MemberStack.reload()
  }

  const loadConstantContact = document.createElement("script")
  loadConstantContact.appendChild(document.createTextNode(`var showConstantContactForm = () => { if(typeof SignUpFormWidget !== 'undefined' && SignUpFormWidget.main) {SignUpFormWidget.main();} else {setTimeout(showConstantContactForm, 100);}}; showConstantContactForm();`))
  document.head.appendChild(loadConstantContact)
}

export const onClientEntry = () => {
  if (window.MemberStack !== undefined) {
    window.MemberStack.reload()
  }
}

export const onInitialClientRender = () => {
  // console.log("onInitialClientRender")
  if (window.MemberStack !== undefined) {
    window.MemberStack.reload()
  }
}

export const onPostPrefetchPathname = () => {
  // console.log("onPostPrefetchPathname")
}

export const onPreRouteUpdate = () => {
  if (window.MemberStack !== undefined) {
    window.MemberStack.reload()
  }
  // console.log("onPreRouteUpdate")
}

export const onPrefetchPathname = () => {
  // console.log("onPrefetchPathname")
}

export const onRouteUpdateDelayed = () => {
  // console.log("onRouteUpdateDelayed")
}
